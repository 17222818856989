import { OrderDoc, OrderProduct, OrderStatusCode } from '@gooduncles/gu-app-schema';
import { message } from 'antd';
import { FC, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { safeBottomForFooterButton } from 'src/lib/1/constant';
import { orderStatusKr } from 'src/lib/1/string-map';
import { textSort } from 'src/lib/1/util';
import { LogDoc, LogOrder } from 'src/lib/3/schema-log';
import { CommerceLogger } from 'src/lib/4/logger';
import { getDeliveredAtKrFormat, getOrderProductsDiffs, loadPrevOrderProducts } from 'src/lib/4/order-util';

import useCart from 'src/redux/hooks/useCart';
import { selectUser } from 'src/redux/slices/auth';
import { selectSubmiited } from 'src/redux/slices/order';
import { useAppSelector } from 'src/redux/store';

import useStoreIssuesByIds from 'src/hooks/useStoreIssuesByIds';

import { useApp } from 'src/stores/app-context';

import EmptyList from '../Common/EmptyList/EmptyList';
import FooterButton from '../Common/FooterButton';
import OrderInfo from '../Common/OrderInfo/OrderInfo';
import OrderItem from '../Common/OrderItem/OrderItem';
import StoreIssueItem from '../Common/StoreIssueItem/StoreIssueItem';
import TopNavigationBar from '../Common/TopNavigationBar/TopNavigationBar';
import classes from './OrderDetail.module.scss';

const logger = CommerceLogger.getInstance();

type OrderDetailProps = {
  order?: OrderDoc;
  logs?: LogDoc<LogOrder>[];
};

const OrderDetail: FC<OrderDetailProps> = ({ order, logs }) => {
  const { setShowLoading } = useApp();
  const user = useAppSelector(selectUser, (prev, next) => prev?._id === next?._id);
  const submittedOrder = useAppSelector(selectSubmiited);
  const [searchParams] = useSearchParams();
  const issueIds = searchParams.getAll('issue-id');
  const { storeIssues } = useStoreIssuesByIds(issueIds.length > 0 ? issueIds : null);
  const { onSetProducts } = useCart();
  const navigate = useNavigate();
  const products = getOrderProductsDiffs(order?.products, logs ?? []);

  const issuesTotalAmount = storeIssues
    ? storeIssues.reduce((acc, cur) => acc + ((cur.supplyPrice ?? 0) + (cur.tax ?? 0)) * (cur.volume ?? 1), 0)
    : null;

  const goToCartWithNewCart = useCallback(
    async (orderProducts: OrderProduct[]) => {
      if (submittedOrder) {
        logger.logCommerce(`이전 주문 불러오기 실패 - 이미 생성한 주문이 있습니다.`);
        message.error('이미 생성한 주문이 있습니다.');
        return;
      } else {
        logger.logCommerce(`주문 목록에 있는 주문 불러오기 - orderId: ${order?._id}, orderDate: ${order?.orderDate}`);

        setShowLoading(true);
        const products = await loadPrevOrderProducts(orderProducts);
        if (products) {
          onSetProducts(products);
          navigate('/shopping-cart');
        }
        setShowLoading(false);
      }
    },
    [navigate, onSetProducts, order?._id, order?.orderDate, setShowLoading, submittedOrder]
  );

  if (!order) {
    return <EmptyList message='삭제되었거나, 잘못된 주문입니다.' />;
  }

  const title =
    order.orderStatus === OrderStatusCode.DELIVERED
      ? getDeliveredAtKrFormat(order.deliveredAt, order.orderStatus, order._id)
      : orderStatusKr[order.orderStatus];

  return (
    <div>
      <TopNavigationBar title={title ?? '오류가 있습니다.'} leftButton />
      <OrderInfo
        order={order}
        paidAmount={issuesTotalAmount ? order.paidAmount + issuesTotalAmount : order.paidAmount}
      />
      <section className={classes.orderDetail}>
        {products &&
          products
            .sort((a, b) => textSort(a.fullName, b.fullName))
            ?.map((product) => {
              const orderProduct = order?.products?.find((op) => op.productId === product.productId);
              return <OrderItem key={product.productId} orderProduct={product} volume={orderProduct?.volume} />;
            })}
        {storeIssues && storeIssues.length > 0 && (
          <>
            <p className={classes.issueHeader}>※ 특이사항</p>
            {storeIssues?.map((storeIssue) => {
              return <StoreIssueItem key={storeIssue._id} storeIssue={storeIssue} />;
            })}
            <p className={classes.issueFooter}>안내된 내용은 다음 정산에 포함됩니다.</p>
          </>
        )}
      </section>

      {user && order.products?.length > 0 && (
        <FooterButton
          text='이 주문 그대로 장바구니에 담기'
          onClick={() => goToCartWithNewCart(order.products)}
          bottom={safeBottomForFooterButton}
        />
      )}
    </div>
  );
};

export default OrderDetail;
